import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { useEffect } from 'react';

const auth0Config = {
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
    redirectUri: window.location.origin,
};

export const Auth0 = ({ children }) => {
    const auth0Client = useAuth0();

    // Handle silent authentication on component mount
    useEffect(() => {
        const initAuth = async () => {
            // Check if user is authenticated
            if (!auth0Client.isAuthenticated) {
                try {
                    // Attempt to renew the session
                    await auth0Client.loginWithPopup();
                } catch (error) {
                    console.error("Failed to renew session", error);
                }
            }
        };

        initAuth();
    }, [auth0Client]);

    return (
        <Auth0Provider {...auth0Config}>{children}</Auth0Provider>
    );
};

export const useAuth = () => {
    const auth0Client = useAuth0();

    const loginWithRedirect = () => {
        return auth0Client.loginWithRedirect({
            connection: 'google-oauth2' // Replace with your Google connection ID
        });
    };

    const logout = () => {
        console.log('Logout function called'); // add this line to debug
        return auth0Client.logout();
    };


    const getTokenSilently = () => {
        return auth0Client.getTokenSilently();
    };

    return { ...auth0Client, loginWithRedirect, logout, getTokenSilently };
};
