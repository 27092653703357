import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { StyledRoot, StyledNavigationList, StyledNavigationItem } from 'baseui/header-navigation';
import { Block } from 'baseui/block';
import axios from 'axios'; // You can use any other library to perform HTTP requests.

const Menu = () => {
    const [menuItems, setMenuItems] = useState([]);  // Setup state

    useEffect(() => {
        // Make a request to your server to get the menu items
        //axios.get('http://localhost:8081/collection/Menu', {
        axios.get('https://controller-dot-e-dra.tommyrivers.ca/collection/Menu', {
            params: {
                parentId: "1rGe1kq6QXyBlB32ZJACmGnaVYio_BbKW" // replace this with your actual parentId
            }
        })
            .then(response => {
                console.log(response.data);
                setMenuItems(response.data);
            })
            .catch(error => {
                console.error('Error fetching menu items:', error);
            });
    }, []);

    return (
        <StyledRoot style={{
            border: 'none',
            position: 'sticky',
            top: '60px',
            backgroundColor: '#f8f9fa',
            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.25)',
            zIndex: 100,
            display: 'flex',
            justifyContent: 'center'
        }}>
            <div style={{maxWidth: '1200px', width: '100%'}}>
                <StyledNavigationList $align="center">
                    {/** <StyledNavigationItem>
                        <Link
                            to="/"
                            style={{
                                textDecoration: 'none',
                                fontWeight: 'bold',
                                color: '#262263',
                            }}
                        >
                            Home
                        </Link>
                    </StyledNavigationItem>**/}
                    <StyledNavigationItem>
                        <Link
                            to="/my-apps"
                            style={{
                                textDecoration: 'none',
                                fontWeight: 'bold',
                                color: '#262263',
                            }}
                        >
                            My Apps
                        </Link>
                    </StyledNavigationItem>
                    <StyledNavigationItem>
                        <Link
                            to="/new-client"
                            style={{
                                textDecoration: 'none',
                                fontWeight: 'bold',
                                color: '#262263',
                            }}
                        >
                            New Client
                        </Link>
                    </StyledNavigationItem>
                    {menuItems.map(item => (
                        <StyledNavigationItem key={item._id}>
                            <Link
                                to={`/menu/${item.id}`}
                                style={{
                                    textDecoration: 'none',
                                    fontWeight: 'bold',
                                    color: '#262263',
                                }}
                            >
                                {item.name}
                            </Link>
                        </StyledNavigationItem>
                    ))}
                </StyledNavigationList>
            </div>
            <Block paddingRight="scale400"/>
        </StyledRoot>
    );
}
export default Menu;
