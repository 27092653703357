import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
//import Home from "./pages/Home";
import Login from "./pages/Login";
import NewClient from "./pages/NewClient";
import PrivateRoute from "./utils/PrivateRoute";
import { Global, css } from "@emotion/react";
import Layout from './components/Layout';
import MenuItemPage from "./pages/MenuItemPage";
import MyApps from "./pages/MyApps";

const globalStyles = css`
  body {
    font-family: "Roboto", sans-serif;
  }
`;

function App() {
    return (
        <Router>
            <div className="App">
                <Global styles={globalStyles} />
                <Routes>
                    <Route path="/" element={
                        <PrivateRoute>
                            <Layout>
                                <MyApps />
                            </Layout>
                        </PrivateRoute>
                    } />
                    <Route path="/new-client" element={
                        <PrivateRoute>
                            <Layout>
                                <NewClient/>
                            </Layout>
                        </PrivateRoute>
                    } />
                    <Route path="/menu/:id" element={
                        <PrivateRoute>
                            <Layout>
                                <MenuItemPage />
                            </Layout>
                        </PrivateRoute>
                    } />
                    <Route path="/my-apps" element={
                        <PrivateRoute>
                            <Layout>
                                <MyApps/>
                            </Layout>
                        </PrivateRoute>
                    } />
                    <Route path="/login" element={<Login />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
