import React from 'react';
import { createRoot } from 'react-dom/client';
import { Auth0Provider } from '@auth0/auth0-react';
import { ThemeProvider, LightTheme } from 'baseui';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import App from './App';
import './index.css';

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

const onRedirectCallback = (appState) => {
    window.history.replaceState(
        {},
        document.title,
        appState && appState.returnTo ? appState.returnTo : window.location.pathname
    );
};

const engine = new Styletron();

// Use createRoot from 'react-dom/client'
const root = createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            // Replace redirectUri with authorizationParams.redirect_uri
            authorizationParams={{
                redirect_uri: window.location.origin
            }}
            onRedirectCallback={onRedirectCallback}
        >
            <StyletronProvider value={engine}>
                <ThemeProvider theme={LightTheme}>
                    <App />
                </ThemeProvider>
            </StyletronProvider>
        </Auth0Provider>
    </React.StrictMode>
);
