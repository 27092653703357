import { Navigate } from 'react-router-dom';
import { useAuth } from './auth'; // Maintain the import statement

const PrivateRoute = ({ children }) => {
    const auth = useAuth(); // Use the hook

    // Output the auth status to console
    console.log('Auth status:', auth.isAuthenticated);

    // If the auth state is loading, show a loading message
    if (auth.isLoading) {
        return <div>Loading...</div>;
    }

    // Return the children if the user is authenticated, else navigate to login
    return auth.isAuthenticated ? children : <Navigate replace to="/login" />;
};

export default PrivateRoute;
