import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import backgroundImage from "../background-image.jpeg";

const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundImage: `url(${backgroundImage})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
};

const buttonStyle = {
    fontSize: '1.2rem',
    padding: '12px 24px',
    border: 'none',
    borderRadius: '4px',
    backgroundColor: '#262263',
    color: '#fff',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',

};

const Login = () => {
    const { loginWithRedirect } = useAuth0();

    return (
        <div style={containerStyle}>
            <button
                style={buttonStyle}
                onClick={() => loginWithRedirect()}
                onMouseEnter={(e) => (e.target.style.backgroundColor = "#1a1a5b")}
                onMouseLeave={(e) => (e.target.style.backgroundColor = "#262263")}
            >
                Log In
            </button>
        </div>
    );
};

export default Login;
