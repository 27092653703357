/** @jsxImportSource @emotion/react */
import React, { useState, useCallback } from 'react';
// import axios from 'axios'; // Commented out for testing
import { css } from "@emotion/react";
import asset5 from '../assets/asset-5.png';  // or .png or whatever the extension is
import '../css/NewClient.css';
import axios from "axios";
import {useAuth0} from "@auth0/auth0-react";

const formStyles = css`
  background-image: url(${asset5});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  margin: 0 auto;
  border-radius: 10px;
  width: 100%;
  box-sizing: border-box;
`;

const headerStyles = css`
  text-align: center;
  font-size: 1.7rem;
  color: #1a1a5b; // change text color
  padding-bottom: 1em;

`;

const subStyles = css`
  text-align: left;
  font-size: 1.2rem;
`;

const paragraphStyles = css`
  text-align: left;
`;

const contentStyles = css`
  display: flex;
  flex-direction: column; // Ensures the title and grid are in a column layout (stacked vertically)
  align-items: center;   // Centering items horizontally
  padding-bottom: 4em;
  color: #1a1a5b; // change text color
`;

const responseMessageStyles = css`
  color: white;
  padding: 10px 20px;
  border-radius: 25px;
  margin-top: 10px;
  text-align: center;
  display: inline-flex;  // Changed to 'inline-flex'
  align-items: center;  // Added to vertically center the text
  justify-content: center;  // Added to horizontally center the text
  font-size: 1rem;
  min-width: 80px;  // Changed to 'min-width' so the pill can expand if the message is longer
  height: 10px;
`;

const NewClient = () => {
    const { user } = useAuth0();
    const userEmail = user.email;
    const [inputValue, setInputValue] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [responseStatus, setResponseStatus] = useState('');
    const [language, setLanguage] = useState('EN');


    const handleSubmit = useCallback(async (e) => {
        e.preventDefault();
        if (inputValue.trim() === '') { // Check if the inputValue is an empty string or consists only of white-space characters
            setResponseMessage('Please enter a name');
            setResponseStatus('error');
            setIsSubmitting(false);

            // Add a timeout to clear the message
            setTimeout(() => {
                setResponseMessage('');
            }, 3000);

            return; // Do not continue with the form submission if the inputValue is an empty string
        }

        setIsSubmitting(true);
        setResponseMessage('Loading...');
        setResponseStatus('loading');

        try {
            // Simulate API call with a console log for now
            //console.log(`Sending name: ${inputValue}`);
             //await axios.post('http://localhost:8081/start', {
            await axios.post('https://controller-dot-e-dra.tommyrivers.ca/start', {
                 name: inputValue,
                email: userEmail,
                 language: language,

             });

            setInputValue('');
            setResponseMessage('Looks like that worked!');
            setResponseStatus('success');

            setTimeout(() => {
                setResponseMessage('');
                setIsSubmitting(false);
            }, 10000);
        } catch (error) {
            console.error('Error submitting the form:', error);
            setResponseMessage('Oops, there might be something wrong. Please contact ini@theneteffect.ca');
            setResponseStatus('error');

            setTimeout(() => {
                setResponseMessage('');
                setIsSubmitting(false);
            }, 10000);
        }
    }, [inputValue, userEmail, language]);



    return (
        <div className="container">
            <h1 css={headerStyles}>Create a new client</h1>
            <form css={formStyles} onSubmit={handleSubmit}>
                <input
                    type="text"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    placeholder="Client name"
                    className="input-field"
                />
                <select value={language} onChange={(e) => setLanguage(e.target.value)}>
                    <option value="EN">English</option>
                    <option value="FR">French</option>
                </select>
                <button
                    type="submit"
                    disabled={isSubmitting}
                    className="submit-button"
                    style={{ marginTop: '10px' }}
                >
                    Submit
                </button>
                {responseMessage &&
                    <p css={[responseMessageStyles,
                        responseStatus === 'success' ? {backgroundColor: 'green'} :
                            responseStatus === 'error' ? {backgroundColor: 'red'} :
                                {backgroundColor: 'gray'}]}>
                        {responseMessage}
                    </p>
                }
            </form>
            <div css={contentStyles}>
                <div>
                    <h2 css={subStyles}>Creates a new client folder on the drive and a new client board on Monday</h2>
                    <p css={paragraphStyles}>
                        1. Carefully type in the client name and select a language, then submit when you're done. <br/> <br/>
                        2. Give the automation at least 3-5 minutes to complete. The links on the newly created Monday board may take some time to update. Please give it time! <br/><br/>
                        3. Contact ini@theneteffect if you think something is broken.
                    </p>
                </div>
                <div></div>
            </div>
        </div>
    );
};

export default NewClient;
