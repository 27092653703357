import { Fragment } from 'react';
import Header from "./Header";
import Menu from "./Menu";
import { css, Global } from "@emotion/react";

const layoutStyles = css`
        .content-div {
                width: 50%; /* The div takes up 1/3rd of the width of its parent */
                margin: 0 auto; /* This centers the div horizontally */
                display: flex; /* These three lines center the content of the div */
                justify-content: center;
                align-items: flex-start;
                > * {
                        flex-shrink: 0;
                        width: 100%;
                }
        }
`;

// Let's assume that the height of the Header is 50px
const headerHeight = 50;

const Layout = ({ children }) => (
    <Fragment>
            <Header style={{position: 'sticky', top: 0, zIndex: 1}}/>
            <Menu style={{position: 'sticky', top: `${headerHeight}px`, zIndex: 1}}/>
            <Global styles={layoutStyles} />
            <div className="content-div">
                    {children}
            </div>
    </Fragment>
);

export default Layout;
