import React from 'react';
import {Link} from 'react-router-dom';
import {StyledRoot, StyledNavigationList, StyledNavigationItem} from 'baseui/header-navigation';
import {Avatar} from 'baseui/avatar';
import {StatefulPopover, PLACEMENT} from 'baseui/popover';
import {Menu} from 'baseui/menu';
import {Block} from 'baseui/block';
import {Button} from 'baseui/button';
import logo from '../logo.png';
import {useAuth} from '../utils/auth';
//import axios from "axios";

const Header = () => {
    const {loginWithRedirect, isAuthenticated, logout, user} = useAuth();

    const dropdownItems = [
        {
            label: (
                <Button
                    onClick={logout}
                    kind="minimal"
                    overrides={{
                        BaseButton: {
                            style: () => ({
                                padding: 0,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: '0',
                                backgroundColor: 'transparent',
                                boxShadow: 'none',
                                zIndex: 500,
                            }),
                        },
                    }}
                >
                    Logout
                </Button>
            ),
            onClick: () => logout(),
        },
      /*  {
            label: (
                <Button
                    //onClick={() => axios.post('https://controller-dot-e-dragon-376422.uc.r.appspot.com/force-sync')}
                    onClick={() => axios.post('http://localhost:8081/force-sync')}
                    kind="minimal"
                    overrides={{
                        BaseButton: {
                            style: () => ({
                                padding: 0,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: '0',
                                backgroundColor: 'transparent',
                                boxShadow: 'none',
                                zIndex: 500,
                            }),
                        },
                    }}
                >
                    Force Sync
                </Button>
            ),
            //onClick: () => axios.post('https://controller-dot-e-dragon-376422.uc.r.appspot.com/force-sync'),
            onClick: () => axios.post('http://localhost:8081/force-sync'),
        },*/
        // Add more menu items here if needed
    ];

    return (
        <StyledRoot style={{border: 'none', position: 'sticky', top: 0, zIndex: 101}}>
            <StyledNavigationList $align="left">
                <Link to={'/'} style={{textDecoration: 'none'}}>
                    <StyledNavigationItem>
                        <img src={logo} alt="Logo" style={{maxHeight: "60px", width: "auto", scale: "1.5"}}/>
                        {/**<span style={{marginLeft: '10px', fontSize: '15px', fontFamily: 'Roboto', color: '#262263', fontWeight: 'bold'}}>THE NET</span>**/}
                    </StyledNavigationItem>
                </Link>
            </StyledNavigationList>
            <StyledNavigationList $align="right">
                {/**} <StyledNavigationItem style={{marginRight: '0px'}}>
                    <input
                        type="search"
                        placeholder="Search"
                        style={{
                            borderRadius: '25px',
                            padding: '5px 20px',
                            width: '300px',
                            border: '1px solid #ccc',
                            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
                        }}
                    />
                </StyledNavigationItem> }**/}
                {!isAuthenticated ? (
                    <StyledNavigationItem>
                        <Button onClick={loginWithRedirect}>Log in</Button>
                    </StyledNavigationItem>
                ) : (
                    <StyledNavigationItem>
                        <StatefulPopover
                            content={({close}) => (
                                <Menu
                                    items={dropdownItems}
                                    onItemSelect={({item}) => {
                                        console.log('Menu item selected:', item.label);
                                        item.onClick();
                                        close();
                                    }}
                                    overrides={{
                                        ListItem: {
                                            style: {
                                                cursor: 'pointer',
                                            },
                                        },
                                    }}
                                />
                            )}
                            placement={PLACEMENT.bottomLeft}
                            overrides={{
                                Body: {
                                    style: {
                                        zIndex: 102 // Setting zIndex higher than Header and Menu component
                                    }
                                },

                            }}
                        >
                            <Button
                                kind="minimal"
                                overrides={{
                                    BaseButton: {
                                        style: () => ({
                                            padding: 0,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }),
                                    },
                                }}
                            >
                                <Avatar name={user.name} src={user.picture} size="scale1000" />
                            </Button>
                        </StatefulPopover>
                    </StyledNavigationItem>
                )}
            </StyledNavigationList>
            <Block paddingRight="scale400"/>
        </StyledRoot>
    );
};

export default Header;
