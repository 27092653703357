import img1 from './newAssets/Asset 1.png';
import img2 from './newAssets/Asset 2.png';
import img3 from './newAssets/Asset 3.png';
import img4 from './newAssets/Asset 4.png';
import img5 from './newAssets/Asset 5.png';
import img6 from './newAssets/Asset 6.png';
import img7 from './newAssets/Asset 7.png';
import img8 from './newAssets/Asset 8.png';
import img9 from './newAssets/Asset 9.png';
import img10 from './newAssets/Asset 10.png';
// Continue for all images

const images = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10]; // Continue for all images

export default images;
