/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from 'axios';
import { css } from "@emotion/react";
import images from '../assets/images';

const pageStyles = css`
  //... (unchanged CSS rules)
  padding: 60px;
  overflow-x: hidden; // Add this line

  a {
    text-decoration: none; /* This removes the underline */
    color: inherit; /* This will make the link color the same as the surrounding text */
  }

  a:visited {
    color: inherit; /* This will make the visited link color the same as the surrounding text */
  }

  a:hover {
    text-decoration: underline; /* This adds an underline when hovering over the link */
  }

  .folder {
    font-size: 1.3em;
    margin-bottom: 1em;
    text-align: left;
    color: #1a1a5b;
  }

  .file-card-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(300px, 100%), 1fr));
    gap: 1em;

    @media (min-width: 1300px) {
      grid-template-columns: repeat(3, 1fr);
      gap: 1em;
    }
  }

  .file-card {
    border: none;
    border-radius: 15px;
    padding: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 200px; // Adjust this value according to your needs
    outline: none;
    width: 100px;   // Adjust as necessary
    height: 30px;  // Adjust as necessary

    @media (min-width: 1300px) {
      height: 100px; // Adjust this value according to your needs

    }
  }

  .file-card-title {
    font-size: 1em; /* Adjust the font size here */
    text-align: left;
    margin-top: 0.5em;
    font-weight: bold; /* Add font-weight for emphasis */
    color: #1a1a5b;
  }
`;

const sectionContainerStyles = css`
  margin-bottom: 50px;
  padding: 20px;  // Added padding inside the container
  border-radius: 30px;  // Rounded corners

  // default background-color removed to add it on a per-section basis
`;

function MenuItemPage() {
    let { id } = useParams();
    id = id || '1dfEdenEV8Nd0L_7kTip1zaXpVUkZjl0V'; // default id if none is provided in the URL
    const [menuItemData, setMenuItemData] = useState(null);
    const [isLoading, setIsLoading] = useState(false); // Add this line - this is a new state for loading

    const imageIndex = React.useRef(0);

    const getNextImage = useCallback(() => {
        const image = images[imageIndex.current];
        console.log(imageIndex.current);
        imageIndex.current = (imageIndex.current + 1) % images.length;
        return image;
    }, []);


    const fetchMenuItems = useCallback(async (parentId, depth = 1) => {
        setIsLoading(true); // Set loading to true before starting the request
        //const response = await axios.get('http://localhost:8081/collection/Menu', {
        const response = await axios.get('https://controller-dot-e-dra.tommyrivers.ca/collection/Menu', {
            params: {
                parentId
            }
        });
        setIsLoading(false); // Set loading to false after the request is done
        const data = response.data;
        for (let item of data) {
            item.depth = depth; // Add depth property here
            if (item.mimeType === 'application/vnd.google-apps.folder' && depth < 2) {
                item.children = await fetchMenuItems(item.id, depth + 1);
                for(let child of item.children){
                    child.image = getNextImage();
                }
            }
        }

        return data;
    }, [getNextImage]);

    const fetchAndSetMenuItems = useCallback(async () => {
        //... (unchanged logic)
        const data = await fetchMenuItems(id);
        for (let item of data) {
            // Add a random image to each item
           // item.image = getNextImage();;
            if (item.mimeType === 'application/vnd.google-apps.folder' ) {
                item.children = await fetchMenuItems(item.id);
                for(let child of item.children){
                    // Add a random image to each child
                    child.image = getNextImage();
                }
            }
        }
        setMenuItemData(data);
}, [id, fetchMenuItems, getNextImage]);

    useEffect(() => {
        fetchAndSetMenuItems().then();
    }, [fetchAndSetMenuItems]);

    const renderFolderContent = (item, color, prefix) => {
        const backgroundColorStyle = color ? css`
    background-color: ${color}; // Set the section-specific background color
  ` : null; // No background for 'General'
        // Render ONLY files, ignore folders (with depth 2)
        return (
            <div key={item.id} css={[sectionContainerStyles, backgroundColorStyle]}>
                <h1 className="folder">{item.name}</h1>
                <div className="file-card-container">
                    {item.children.filter((child) => child.mimeType !== 'application/vnd.google-apps.folder').map(renderMenuItemAsFile)}
                </div>
            </div>
        );
    }


    const renderGeneralFolder = (item) => {
        return renderFolderContent(item, null, 'General'); // null for no background color
    }

    const renderProjectFolder = (item) => {
        return renderFolderContent(item, '#E0F2F1', 'Project'); // example color, adjust as necessary
    }

    const renderSalesFolder = (item) => {
        return renderFolderContent(item, '#FFF2F1', 'Sales');  // example color, adjust as necessary
    }

    const renderMarketingFolder = (item) => {
        return renderFolderContent(item, '#E5EAF7', 'Marketing');  // example color, adjust as necessary
    }

    const renderMenuItemAsFile = (item) => {
        const imageStyle = {
            backgroundImage: `url(${item.image})`,
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat'
        };

        const isFolder = item.mimeType === 'application/vnd.google-apps.folder';
        const link = isFolder ? `https://drive.google.com/drive/folders/${item.id}` : constructLink(item.id);

        return (
            <a key={item.id} href={link} target="_blank" rel="noopener noreferrer">
                <div>
                    <div className="file-card" style={imageStyle}></div>
                    <p className="file-card-title">{item.name}</p>
                </div>
            </a>
        );
    }

    const renderMenuItem = (item) => {
        const isTopLevelFolder = item.depth === 1;
        const isFolder = item.mimeType === 'application/vnd.google-apps.folder';
        const hasChildren = item.children && item.children.length;

        if (isTopLevelFolder && isFolder) {
            if (item.name.startsWith("General")) {
                return renderGeneralFolder(item);
            } else if (item.name.startsWith("Project")) {
                return renderProjectFolder(item);
            } else if (item.name.startsWith("Sales")) {
                return renderSalesFolder(item);
            } else if (item.name.startsWith("Marketing")) {
                return renderMarketingFolder(item);
            } else {
                return renderFolderContent(item, null, 'Default');
            }
        } else if (isFolder) {
            if (hasChildren) {
                return renderFolderContent(item, null, 'Subfolder');
            } else {
                return renderEmptyFolder(item);
            }
        } else {
            return renderMenuItemAsFile(item);
        }
    }


    const renderEmptyFolder = (item) => {
        const backgroundColorStyle = css`background-color: #FFF;`;  // some default background for empty folders

        return (
            <div key={item.id} css={[sectionContainerStyles, backgroundColorStyle]}>
                <h1 className="folder">{item.name}</h1>
                <p>No files in this folder.</p>
            </div>
        );
    }

    const constructLink = (id) => {
        return `https://drive.google.com/file/d/${id}`;
    }

    if (isLoading) {
        return <div>Loading...</div>;  // or return a spinner
    }

    if (!menuItemData) {
        return <div>Loading...</div>;
    }

    return (
        <div css={pageStyles}>
            {menuItemData.filter(item => item.name.startsWith("General")).map(renderMenuItem)}
            {menuItemData.filter(item => item.name.startsWith("Project")).map(renderMenuItem)}
            {menuItemData.filter(item => item.name.startsWith("Sales")).map(renderMenuItem)}
            {menuItemData.filter(item => item.name.startsWith("Marketing")).map(renderMenuItem)}
            {menuItemData.filter(item =>
                !["General", "Project", "Sales", "Marketing"].some(prefix => item.name.startsWith(prefix))
            ).map(renderMenuItem)}
        </div>
    );
}

export default MenuItemPage;
