/** @jsxImportSource @emotion/react */
import React from 'react';
import { css, Global } from "@emotion/react";

import AdobeLogo from '../assets/logos/adobe.jpeg';
import BoomerangLogo from '../assets/logos/boomerang.png';
import CanvaLogo from '../assets/logos/canva.webp';
import CheckLogo from '../assets/logos/check the level.jpeg';
import DashlaneLogo from '../assets/logos/dashlane.png';
import DextLogo from '../assets/logos/dext.png';
import FellowLogo from '../assets/logos/fellow.png';
import Fireflies from '../assets/logos/fireflies.jpeg';
import Harvest from '../assets/logos/harrvest.png';
import HRCovered from '../assets/logos/hr covered.png';
import Hubspot from '../assets/logos/hubspot.png';
import QBO from '../assets/logos/qbo.png';
import LinkedIn from '../assets/logos/linkedIn.png';
import Meta from '../assets/logos/meta.png';
import Office from '../assets/logos/microsoft.png';
import Miro from '../assets/logos/miro.png';
import Monday from '../assets/logos/monday.png';
import Panda from '../assets/logos/panda doc.png';
import Streamyard from '../assets/logos/streamyard.png';
import Wagepoint from '../assets/logos/wagepoint.png';


const mainContainerStyles = css`
  display: flex;
  flex-direction: column; // Ensures the title and grid are in a column layout (stacked vertically)
  align-items: center;   // Centering items horizontally
  padding-bottom: 4em; 
  color: #1a1a5b; // change text color
`;


const titleStyles = css`
  text-align: center;
  padding: 2em 0;
  font-size: 2em;
`;

// Styles for the grid
const gridStyles = css`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(300px, 100%), 1fr));
  gap: 1em;

  @media (min-width: 1300px) {
    grid-template-columns: repeat(4, 1fr);
    gap: 1em;
  }
`;

// Styles for App card
const appCardStyles = css`
  padding: 1em;
  border: none;
  border-radius: 10px;
  text-align: center;

  img {
    width: 100px;
    height: 100px;
    object-fit: contain; 
  }

  p {
    margin-top: 1em;
  }
`;


// Data for the apps
const apps = [
    { name: 'Adobe', url: 'https://www.adobe.com/ca/', logo: AdobeLogo },
    { name: 'Boomerang', url: 'https://www.boomeranggmail.com/', logo: BoomerangLogo },
    { name: 'Canva', url: 'https://www.canva.com/', logo: CanvaLogo },
    { name: 'Check the Level', url: 'https://www.checkthelevel.com/', logo: CheckLogo },
    { name: 'Dashlane', url: 'https://www.dashlane.com/', logo: DashlaneLogo },
    { name: 'Dext', url: 'https://www.dext.com/', logo: DextLogo },
    { name: 'Fellow', url: 'https://www.fellow.app', logo: FellowLogo },
    { name: 'Fireflies', url: 'https://www.fireflies.ai', logo: Fireflies},
    { name: 'Harvest', url: 'https://www.getharvest.com/', logo: Harvest},
    { name: 'HR Covered', url: 'https://www.hrcovered.com', logo: HRCovered},
    { name: 'Hubspot', url: 'https://www.hubspot.com/', logo: Hubspot},
    { name: 'Intuit QBO', url: 'https://quickbooks.intuit.com/ca/online/', logo: QBO },
    { name: 'LinkedIn', url: 'https://www.linkedin.com/', logo: LinkedIn },
    { name: 'Meta', url: 'https://www.facebook.com/home', logo: Meta },
    { name: 'Micorsoft Office', url: 'https://www.office.com/', logo: Office },
    { name: 'Miro', url: 'https://www.miro.com/', logo: Miro },
    { name: 'Monday', url: 'https://www.monday.com/', logo: Monday },
    { name: 'PandaDoc', url: 'https://www.pandadoc.com/', logo: Panda },
    { name: 'Streamyard', url: 'https://www.streamyard.com/', logo: Streamyard },
    { name: 'Wagepoint', url: 'https://www.wagepoint.com/', logo: Wagepoint }



    //... add more apps
];

function MyApps() {
    return (
        <>
            <Global styles={css`
                a {
                    text-decoration: none;
                    color: inherit;
                }
            `} />
            <main css={mainContainerStyles}>
                <h1 css={titleStyles}>My Apps</h1>
                <div css={gridStyles}>
                    {apps.map((app) => (
                        <a key={app.name} href={app.url} target="_blank" rel="noopener noreferrer">
                            <div className="app-card" css={appCardStyles}>
                                <img src={app.logo} alt={`${app.name} logo`}/>
                                <p>{app.name}</p>
                            </div>
                        </a>
                    ))}
                </div>
            </main>
        </>
    );
};


export default MyApps;

